// eslint-disable react-hooks/exhaustive-deps

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { Router, navigate } from '@reach/router';
import queryString from 'query-string';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import { setCookie, buildRealtorAPIURL, setTrackingCookie } from '@beelineloans/cx-library/src/utils/helpers';
import { get } from '@beelineloans/cx-library/src/utils/fetch';
import Container from '@beelineloans/cx-library/src/components/layout/Container';
import LoadingCircle from '@beelineloans/cx-library/src/components/LoadingCircle';
import { Theme } from '@beelineloans/cx-library/src/theme/default';
import GlobalStyle from '@beelineloans/cx-library/src/theme/global-styles';
// import Index from './index';

const CaptureReferral = ({ location }) => {
  const path = location.pathname.slice(-1) === '/' ? location.pathname.slice(0, -1) : location.pathname;
  const parts = path.split('/');
  const code = parts.length > 0 ? (parts[parts.length - 1] ?? '').toLowerCase() : null;
  const redTrackDefaultCampaignId = '61fb2edccb5011000177abbd';

  const setTracking = (refCode, rtkCampaignId, utms, realtorName, realtorId, realtorFirm) => {
    setTrackingCookie(`${utms}&rcode=${refCode}&rname=${realtorName}&rid=${realtorId}&rfirm=${realtorFirm}`); // &rtkcmpid=${rtkcmpid}`);

    const cookieDetails = {
      code: refCode,
      name: realtorName,
      id: realtorId,
      realtorFirmName: realtorFirm,
      rtkcmpid: rtkCampaignId
    };

    setCookie('ref', JSON.stringify(cookieDetails));
  };

  useEffect(() => {
    const queryParams = location.search ? queryString.parse(location.search) : {};
    const inputUTMs = JSON.stringify(queryParams).replace(/"/g, '').replace(/=/g, ':').replace(/{/g, '').replace(/}/g, '').replace(/,/g, '|');
    let utmSource = 'realtor';
    let utmMedium = 'realtorlink';
    let utmCampaign = code || 'NOTSET';
    let doLookup = true;
    let rtkcmpid;
    let cpaName;
    let redirectPage = CONST.LINKS.MAIN.HOME;

    // if (['stessa', 'trainorcpa'].includes(code)) {
    //   utmMedium = 'partner';
    //   utmSource = code;
    // }

    if (['roofstock', 'doorvest', 'stessa', 'awning', 'trainorcpa', 'halo'].includes(code)) {
      utmMedium = 'partner';
      utmSource = code;
      // redirectPage = CONST.LINKS.MAIN.INVESTORS.DEFAULT;
    }

    if (['trainorcpa', 'halo', 'biggerpockets', 'biglaw', 'smartstreets'].includes(code)) {
      doLookup = false;
      utmSource = code;
      utmMedium = queryParams.utm_medium ?? '';
      // redirectPage = CONST.LINKS.MAIN.INVESTORS.DEFAULT;

      if (code === 'trainorcpa') {
        cpaName = 'J. Trainor %26 Company';
        rtkcmpid = '61fb3034cb5011000177abce';
      } else if (code === 'biglaw') {
        cpaName = 'BigLaw Investor';
        rtkcmpid = 'r49r6TE4NEXuyyhF437BjPQV5PG7K0';
        redirectPage = CONST.LINKS.MAIN.INVESTORS.DEFAULT;
      } else if (code === 'halo') {
        cpaName = 'Halo';
        rtkcmpid = '61fb3052cb5011000177abd0';
      } else if (code === 'smartstreets') {
        cpaName = 'Smart Streets';
        rtkcmpid = 'xzq74blVTNpnc2BWKhsfmJEpRU7CxY';
      } else if (code === 'biggerpockets') {
        cpaName = 'Bigger Pockets';
        rtkcmpid = '62ce05a385e2d000019d7901';
        utmMedium = 'podcast';
        utmCampaign = 'podcast-october-2022'; // queryParams.utm_campaign ?? utmCampaign;
        redirectPage = CONST.LINKS.MAIN.INVESTORS.DEFAULT;
      }
    }

    const utmParams = `?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}&utm_term=${queryParams.utm_term}&utm_content=${inputUTMs}`;
    let redirectURL = `${redirectPage}${utmParams}`;

    if (!code) {
      console.error(`Could not find referral code in path: ${location.pathname}`);
      navigate(`${redirectURL}&rtkcmpid=${redTrackDefaultCampaignId}`);
    } else if (doLookup) {
      // lookup realtor by code
      get(buildRealtorAPIURL(`${CONST.LINKS.EXTERNAL.API.REALTOR.CODE}/${code}`))
        .then((response) => {
          if (response.data.status === 'OK') {
            const realtorName = response.data.name;
            const realtorId = response.data.id;
            const realtorFirmName = response.data.firmName;
            rtkcmpid = code === 'roofstock' ? '624e8bf82744a700012a56f3' : response.data.redTrackCampaignId;

            localStorage.setItem('rtkDone', '0'); // set redtrack as not done, GTM click generation will run when this page navigates
            setTracking(code, rtkcmpid, utmParams, realtorName, realtorId, realtorFirmName);
            // setTrackingCookie(`?rcode=${code}&rname=${realtorName}&rid=${realtorId}&rfirm=${realtorFirmName}`); // &rtkcmpid=${rtkcmpid}`);
            // const cookieDetails = {
            //   code,
            //   name: realtorName,
            //   id: realtorId,
            //   realtorFirmName,
            //   rtkcmpid
            // };

            // setCookie('ref', JSON.stringify(cookieDetails));

            if (code === 'roofstock') {
              redirectURL = `${CONST.LINKS.MAIN.LANDING.ROOFSTOCK}${utmParams}`;
            } else if (code === 'awning') {
              redirectURL = `${CONST.LINKS.MAIN.LANDING.AWNING}${utmParams}`;
            }

            navigate(`${redirectURL}&realtorFirm=${realtorFirmName}&rtkcmpid=${rtkcmpid}`, { state: { referralCode: code, code, realtorName, realtorFirmName, realtorId } });
          } else {
            console.error(`Could not find referral code ${code}. Path: ${location.pathname}. Message: ${response.message}`);
            navigate(`${redirectURL}&rtkcmpid=${redTrackDefaultCampaignId}`);
          }
        })
        .catch(() => {
          console.error(`Could not find referral code ${code}. Path: ${location.pathname}`);
          navigate(`${redirectURL}&rtkcmpid=${redTrackDefaultCampaignId}`);
        });
    } else {
      setTracking(code, rtkcmpid, utmParams, cpaName, 0, cpaName);
      navigate(`${redirectURL}&realtorFirm=${cpaName}&rtkcmpid=${rtkcmpid}`, { state: { referralCode: code, realtorName: cpaName, realtorFirmName: cpaName, realtorId: 0 } });
    }
  }, []);

  return (
    <Container>
      <LoadingCircle />
    </Container>
  );
};

CaptureReferral.propTypes = {
  location: PropTypes.object
};

const Referral = () => (
  <ThemeProvider theme={Theme}>
    <GlobalStyle />
    <Router>
      <CaptureReferral default noThrow />
    </Router>
  </ThemeProvider>
);

export default Referral;
